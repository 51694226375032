module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_graphql@16.10.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f1598c190483d3c90addf225dd2435c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.8.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
